/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/utils.scss";
@import "./theme/icons.scss";

// http://ionicframework.com/docs/v2/theming/

// https://forum.ionicframework.com/t/is-there-any-way-to-hide-search-icon-from-searchbar/55889
@import "~ionic4-auto-complete/auto-complete";

.searchbar-search-icon {
  display: none !important;
  width: 0;
  height: 0;
}

.searchbar-input {
  padding-left: 9px !important;
}

// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.

.primary {
  color: var(--ion-color-primary) !important;
}

.secondary {
  color: var(--ion-color-secondary) !important;
}

.tertiary {
  color: var(--ion-color-tertiary) !important;
}

.light {
  color: var(--ion-color-light) !important;
}

.lightneutral {
  color: var(--ion-color-light-neutral) !important;
}

.medium {
  color: var(--ion-color-medium) !important;
}

.neutral {
  color: var(--ion-color-neutral) !important;
}

.dark {
  color: var(--ion-color-dark) !important;
}

.darkneutral {
  color: var(--ion-color-darkneutral) !important;
}

.success {
  color: var(--ion-color-success) !important;
}

.warning {
  color: var(--ion-color-warning) !important;
}

.danger {
  color: var(--ion-color-danger) !important;
}

#sidenav {
  .open {
    max-height: 400px;
  }

  ion-item-group {
    height: auto;
    max-height: 0;
    transition: max-height .5s linear;
  }
}

.relatedSelected {
  color: #34afbc;
}

.secondLevelIcon {
  padding-left: 9px;
  width: 2.1em !important;
}

.icon_backProfile {
  min-height: 2.5rem !important;
}

.backProfile {
  color: #34afbc;
}

.input-cover {
  display: none;
}

// to hide scroll bar in webmode for chrome and similar
.scroll-content::-webkit-scrollbar {
  display: none !important;
}

// to hide scroll bar in webmode for firefox
.scroll-content {
  overflow: -moz-scrollbars-none;
}

// to hide scroll bar in webmode for IE (not tested)
.scroll-content {
  -ms-overflow-style: none;
}



// Popover too wide
.popover-content {
  width: auto !important;
}

.xelinkIcon {
  width: auto;
  height: 2em;
}

// Custom style

.bgNeutral {
  background-color: var(--ion-color-medium) !important;
}

.bgLightneutral {
  background-color: var(--ion-color-light-neutral) !important;
}

.bgDarkneutral {
  background-color: var(--ion-color-tertiary) !important;
}

.nomargin {
  margin: 0px !important;
}

.nopadding {
  padding: 0px !important;
}

.nopaddingLeftRight {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.paddingLeftRight {
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.minimalPadding {
  padding: 0.2em !important;
}

.padSeparator {
  padding-bottom: 0.5em !important;
}

.oddRow {
  background-color: transparent;
}

.evenRow {
  background-color: var(--ion-color-light-neutral);
}

.note {
  font-size: 80% !important;
  font-weight: normal !important;
  color: var(--ion-color-tertiary) !important;
}

.labelLike {
  font-size: 80% !important;
  font-weight: normal !important;
  color: var(--ion-color-medium) !important;
}

.header {
  font-size: larger !important;
  font-weight: bold !important;
}

.headerPrimary {
  font-size: larger !important;
  font-weight: bold !important;
  color: var(--ion-color-primary) !important;
}

.borderRight {
  border-right: 1px solid black;
}

.spacerTop {
  margin-top: 1.5em !important;
}

.spacerLeft {
  margin-left: 1.5em !important;
}

.spacerRight {
  margin-right: 1.5em !important;
}

.subTitle {
  font-size: 110% !important;
  font-weight: bold !important;
}

.inputLike {
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: var(--ion-color-primary) !important;
}

.mawWidth95,
.maxWidth95 {
  max-width: 95% !important;
}

.centerSpinnerElement {
  width: 70px;
  height: 70px;
}

.centerSpinner {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  width: 150px;
  height: 70px;
}

.borderBottomPrimary {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--ion-color-primary);
}

.borderTopPrimary {
  border-top-width: 1px !important;
  border-top-style: solid !important;
  border-top-color: var(--ion-color-tertiary) !important;
}

.inlineInputNumber {
  width: auto;
  color: var(--ion-color-dark);
  font-size: large;
  display: inline-block !important;
  border: none;
  text-align: center;
}

.userAvatar {
  margin: 0 auto;
  width: 60px !important;
  height: 60px !important;
}

.SmallFont {
  font-size: 80% !important;
}

.VerySmallFont {
  font-size: 65% !important;
}

.BigFont {
  font-size: 110% !important;
}

.fontLarger {
  font-size: 1.5rem !important;
}

.biggerMenuButton {
  font-size: 2.2rem !important;
}

.slideScrollContent {
  overflow-y: auto !important;
}

.slide-zoom {
  height: 100%;
  overflow-y: auto !important;
}

.slideScrollContent iframe {
  max-width: 100% !important;
}

.centerIcon {
  text-align: center !important;
  width: 100% !important;
  font-size: 200% !important;
}

.noVerticalMargin {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.secondFab {
  bottom: 70px !important;
}

u.dotted {
  border-bottom: 1px dashed #999;
  text-decoration: none;
}

/* The snackbar - position it at the bottom and in the middle of the screen */
.snackbar {
  visibility: hidden;
  /* Hidden by default. Visible on click */
  min-width: 250px;
  /* Set a default minimum width */
  margin-left: -125px;
  /* Divide value of min-width by 2 */
  background-color: #333;
  /* Black background color */
  color: #fff;
  /* White text color */
  /* text-align: center; /* Centered text */
  text-align: left;
  /* Left aligned text */
  border-radius: 4px;
  /* Rounded borders */
  padding: 8px;
  /* Padding */
  position: absolute;
  /* Sit on top of the screen */
  z-index: 1;
  /* Add a z-index if needed */
  left: 45%;
  /* Center the snackbar */
  width: 80%;
  margin-top: 20px;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
.snackbarShow {
  visibility: visible;
  /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

.maximizeImage {
  min-width: 150% !important;
  height: auto !important;
}

.m {

  &-0,
  &-none {
    margin: 0 !important;
  }

  &-1 {
    margin: 1rem !important;
  }

  &-2 {
    margin: 2rem !important;
  }

  &-auto {
    margin: auto;
  }

  &b- {
    &0 {
      margin-bottom: 0% !important;
    }

    &1 {
      margin-bottom: 1rem !important;
    }

    &2 {
      margin-bottom: 2rem !important;
    }
  }

  &r- {
    &0 {
      margin-right: 0 !important;
    }

    &1 {
      margin-right: 1rem !important;
    }

    &2 {
      margin-right: 2rem !important;
    }

    &5 {
      margin-right: 5px !important;
    }

    &10 {
      margin-right: 10px !important;
    }

    &15 {
      margin-right: 15px !important;
    }
  }

  &l- {
    &0 {
      margin-left: 0 !important;
    }

    &1 {
      margin-left: 1rem !important;
    }

    &2 {
      margin-left: 2rem !important;
    }

    &5 {
      margin-left: 5px !important;
    }

    &10 {
      margin-left: 10px !important;
    }

    &15 {
      margin-left: 15px !important;
    }

    &125 {
      margin-left: 125px !important;
    }
  }

  &t- {
    &0 {
      margin-top: 0 !important;
    }

    &1 {
      margin-top: 1rem !important;
    }

    &2 {
      margin-top: 2rem !important;
    }

    &5 {
      margin-top: 5px !important;
    }

    &10 {
      margin-top: 10px !important;
    }

    &15 {
      margin-top: 15px !important;
    }

    &20 {
      margin-top: 20px !important;
    }

    &25 {
      margin-top: 25px !important;
    }
  }
}

.custom-input {
  width: 90vw !important;
}

.customRewardToast {
  margin-top: 2vh;
  --background: var(--ion-color-primary);
  --border-radius: 35px;
  --width: 90vw;
  --button-color: var(--ion-color-light-neutral);
}

// customize progress bar
.progress-outer {
  padding: 0px !important;
  border-color: #c7c7c7;

  .progress-inner {
    min-width: 0 !important; // fix for default min-width of 15%
    font-size: 0;
    min-height: 10px !important;
  }
}

ion-footer {
  background-color: white;
}

ion-radio {
  visibility: visible;
}

// If there isn't an ion-footer under an ion-content the FAB buttons are displayed too low on the screen
.fabContainer {
  margin-bottom: 10% !important;
}

.fabContainerReverse {
  margin-bottom: -5% !important;
}

.drugSchemaListName {
  .alert-radio-label {
    white-space: unset;
  }
}

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

// alert box

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;

  &.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  &.alert-info {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
}

// This is important for when a font-size change is made in the device settings
ion-icon {
  font-size: 1.1em !important;
  width: 1.5em !important;
  height: 1.3em !important;
}

.summary {
  --max-width: calc(95vw);
}

.override-width {

  @media (min-width: 768px) {
    --width: 80% !important;
    --height: 80% !important;

  }

}

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200);
  --swiper-pagination-color: var(--ion-color-primary);
}
