ion-icon {
    &.custom {
      mask-size: contain;
      mask-position: 50% 50%;
      mask-repeat: no-repeat;
      background: currentColor;
      width: 1em;
      height: 1em;
      &.doubleArrow {
        mask-image: url(../assets/rewind.svg);
      }
  
      &.doubleArrowR {
        mask-image: url(../assets/rewindReverse.svg);
      }
    }
  }
