:root {
  --ion-color-primary: #34afbc;
  --ion-color-primary-rgb: 52, 175, 188;
  --ion-color-primary-contrast: #fafafa;
  --ion-color-primary-contrast-rgb: 250, 250, 250;
  --ion-color-primary-shade: #2e9aa5;
  --ion-color-primary-tint: #48b7c3;

  --ion-color-secondary: #fa9700;
  --ion-color-secondary-rgb: 250, 151, 0;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #dc8500;
  --ion-color-secondary-tint: #fba11a;

  --ion-color-tertiary: #4a4a4a;
  --ion-color-tertiary-rgb: 74, 74, 74;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #414141;
  --ion-color-tertiary-tint: #5c5c5c;

  --ion-color-success: #18b915;
  --ion-color-success-rgb: 14, 124, 12;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0c6d0b;
  --ion-color-success-tint: #268924;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #e84a46;
  --ion-color-danger-rgb: 232, 74, 70;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cc413e;
  --ion-color-danger-tint: #ea5c59;

  --ion-color-dark: #278590;
  --ion-color-dark-rgb: 39, 133, 144;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #22757f;
  --ion-color-dark-tint: #3d919b;

  --ion-color-medium: #989898;
  --ion-color-medium-rgb: 152, 152, 152;
  --ion-color-medium-contrast: #252525;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #868686;
  --ion-color-medium-tint: #a2a2a2;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;

  --ion-color-light-neutral: #fafafa;
  --ion-color-light-neutral-rgb: 250, 250, 250;
  --ion-color-light-neutral-contrast: #000000;
  --ion-color-light-neutral-contrast-rgb: 0, 0, 0;
  --ion-color-light-neutral-shade: #dcdcdc;
  --ion-color-light-neutral-tint: #fbfbfb;

  --ion-color-darkneutral: #4a4a4a;
  --ion-color-darkneutral-rgb: 15, 15, 15;
  --ion-color-darkneutral-contrast: #fafafa;
  --ion-color-darkneutral-contrast-rgb: 250, 250, 250;
  --ion-color-darkneutral-shade: #3d3d3d;
  --ion-color-darkneutral-tint: #414141;

  --ion-color-neutral: #989898;
}

.ion-color-light-neutral {
  --ion-color-base: var(--ion-color-light-neutral);
  --ion-color-base-rgb: var(--ion-color-light-neutral-rgb);
  --ion-color-contrast: var(--ion-color-light-neutral-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-neutral-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-neutral-shade);
  --ion-color-tint: var(--ion-color-light-neutral-tint);
}

.ion-color-darkneutral {
  --ion-color-base: var(--ion-color-darkneutral);
  --ion-color-base-rgb: var(--ion-color-darkneutral-rgb);
  --ion-color-contrast: var(--ion-color-darkneutral-contrast);
  --ion-color-contrast-rgb: var(--ion-color-darkneutral-contrast-rgb);
  --ion-color-shade: var(--ion-color-darkneutral-shade);
  --ion-color-tint: var(--ion-color-darkneutral-tint);
}